<template >
  <div
  class="atleti mx-auto"
  >
  <v-app-bar app
  >
  <v-btn icon @click="dismissModal()">
    <v-icon>mdi-chevron-left</v-icon>
  </v-btn>

  <v-toolbar-title>
    <div class="test-titolo">
      <h2>Atleti</h2>
    </div>
  </v-toolbar-title>


</v-app-bar>
<v-app>


  <v-container fluid>
      <v-card
              class="mx-auto"
              max-width="450"
              >
                  <v-form><v-text-field
                          label="Cerca"
                          placeholder="Nome atleta"
                          v-model="search"
                          outlined
                          append-icon="mdi-magnify"
                          type="text"
                          ></v-text-field></v-form>

                  <v-spacer></v-spacer>


              <v-list lines="two">
                  <v-list-item
                          v-for="atleta in filteredAtleti"
                          :key="atleta.id"
                          @click = "select(atleta)"
                          >
                              <v-list-item-avatar color="#ccc">
                             <v-img v-if="atleta.url_fotoprofilo" :src="imgAvatar(atleta)">
                             </v-img> 
                             <span v-else class="text-h6" v-text="iniziali(atleta)"></span>
                              </v-list-item-avatar>
                          <v-list-item-content><v-list-item-title
                                  v-text="nomeAtleta(atleta)"></v-list-item-title></v-list-item-content>
                  </v-list-item>


              </v-list>

      </v-card>
  </v-container>


</v-app>
</div>


</template>

<script>
import {  mapGetters } from 'vuex'
import env from "@mixins/environment.js"
import utilityAtleta from "@mixins/utilityAtleta.js"
import _cal from "@src/js/vue/mixins/calendario.js";
import _funzioni from "@src/js/msp/funzioni.js";

export default {
    components: {
    },
    mixins: [
    env,
    utilityAtleta,
    ],
    data: function() {
        return {
            loading: false,
            search: "",
        };
    },

    watch: { },

    mounted() { },

    computed: {
        ...mapGetters('atleti', [
        'atleti',
        'atletaId',
        ]),

            filteredItems: function () {
                const items = this.atleti;
                if (!(items && items.length)) return [];
                // if there is no filter, return everything
                let filtered;
                const  searchIn = ["nome","cognome"];

                if (this.search === "") {
                    filtered = [...items];
                } else {
                    let searchValue = this.cleanString(this.search);
                    let regExp = new RegExp(searchValue,'g');
                    let filter;
                    filter = function (el){
                        let finded = false;
                        searchIn.map((search)=>{
                            finded = finded
                            ||
                            (""+el[search]).toLowerCase().match(regExp);
                        });
                        return finded;
                    }
                    filtered = items.filter(filter);
                }
                return filtered;
            },
        filteredAtleti: function () {
            let atleti = this.filteredItems;
            if (!(atleti && atleti.length)) return atleti;

            return atleti
                .sort((a,b)=>{
                    const nomeAtleta1 = a.cognome + ' ' + a.nome;
                    const nomeAtleta2 = b.cognome + ' ' + b.nome;
                    return (nomeAtleta1.toLowerCase() > nomeAtleta2.toLowerCase()) ? 1 : -1;
                });

        },
    },
    methods: {
            cleanString: function (string) {
                let new_string = string;
                new_string = new_string.trim().toLowerCase();
                return new_string;
            },

        dismissModal () {
            this.$router.go(-1);
        },

    goToToday: async function() {
        let date =  _funzioni.formatDateYmd(new Date());
        let limits = {
            start: date,
            end: _funzioni.formatDateYmd(_cal.addDays(date,7)),
        }
        this.loadCalendar(limits);
    },
    
    loadCalendar: async function ({start, end}) {
        const limits = {
            start: start,
            end: end,
        }

        this.loading = true;
        await this.$store.dispatch("calendario/loadCalendar",limits);
        this.loading = false;
        return true;
    },
        select: async function (atleta) {
            await this.$store.dispatch("app/setAtleta", atleta);
            this.goToToday();
            this.dismissModal();
        }
    }
}

</script>

<style lang="scss"> </style>
